import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';

import logo from '../img/logo.svg';

const NotFoundPage = () => (
  <Layout>
    <main className="home-section">
      <h1>NOT FOUND</h1>
      <p>This page does not exist.</p>
      <Link to="/" title="Logo">
        <img src={logo} alt="I AM STUDIO - Logo" style={{ width: '60px' }} />
      </Link>
    </main>
  </Layout>
);

export default NotFoundPage;
